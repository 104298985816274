import React from 'react';
import { withPrefix } from 'gatsby';

import Layout from '../components/Layout';
// import Logo from '../components/Logo';
// import Button from '../components/Button';
import Header from '../components/Header';

const TosPage = () => {
  return (
    <Layout>
      <Header type="dark" />

      <div className="rounded shadow-lg w-full md:w-2/3 mx-auto bg-white mt-24">
        <div className="p-4 md:p-8 leading-normal text-grey-darkest">
          <h1 className="text-2xl mb-4">Makers Mob Brand Guidelines</h1>

          <h2 className="text-xl mb-4 font-normal">Logo Marks</h2>
          <div>
            <img src={withPrefix('/makers_mob_dark_large.png')} alt="logo" />
          </div>
          <div className="bg-black">
            <img src={withPrefix('/makers_mob_light_large.png')} alt="logo" />
          </div>
          <div className="w-48">
            <img
              src={withPrefix('/makers_mob_mark_dark_large.png')}
              alt="logo"
            />
          </div>
          <div className="w-48 bg-black mb-12">
            <img
              src={withPrefix('/makers_mob_mark_light_large.png')}
              alt="logo"
            />
          </div>

          <h2 className="text-xl mb-4 font-normal">Fonts</h2>
          <p>
            Logo wordmark font is{' '}
            <a
              href="https://fonts.google.com/specimen/Montserrat"
              target="_blank"
              rel="noopener noreferrer"
            >
              Montserrat
            </a>
          </p>
          <p>
            Website font is{' '}
            <a
              href="https://fonts.google.com/specimen/Open+Sans"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Sans
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TosPage;
